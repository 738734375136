import common from "../data/common.json";

export function createOGP({
  path = "",
  pageName,
  noindex,
}: {
  path?: string;
  pageName?: string;
  noindex?: boolean;
} = {}) {
  return {
    title: `${pageName ? `${pageName} | ` : ""}${common.meta.title}`,
    description: common.meta.description,
    "og:type": "website",
    "og:title": common.meta.title,
    "og:description": common.meta.description,
    "og:image": common.meta.og_image,
    "og:url": `${common.meta.url}${path}`,
    "twitter:site": common.meta.twitter_name,
    "twitter:card": "summary_large_image",
    "twitter:title": common.meta.title,
    "twitter:description": common.meta.description,
    ...(noindex && {
      robots: "noindex,nofollow",
    }),
  };
}
